<template>
  <div class="features-items">
    <div v-for="(item, index) in technologies" :key="index">
      <div :class="`row ${item.direction} items-stretch feature`">
        <div
          :class="`col-xs-12 col-sm-8 col-md-8 col-lg-6 col-xl-6 ${item.order} feature-text self-center`"
        >
          <div class="feature-title">
            <q-icon :name="item.icon" class="icons" />
            {{ item.title }}
          </div>
          <div class="feature-descp">{{ item.text }}</div>
        </div>
        <div
          class="
            col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4
            row
            feature-img-wrap
          "
          :style="{ '--bgImg': `url(${item.imageSrc})` }"
        >
          <q-img
            :src="`${item.imageSrc}`"
            loading="lazy"
            no-spinner
            class="feature-img"
            :fit="fillClass"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useQuasar } from "quasar";

export default {
  props: ["technologies"],
  setup() {
    const $q = useQuasar();
    const fillClass = computed(() => {
      if ($q.screen.sm || $q.screen.md) {
        return "contain";
      } else {
        return "fill";
      }
    });

    return {
      fillClass,
    };
  },
};
</script>

<style lang="scss" scoped>
.feature {
  margin: 20px 0;
  min-height: 300px;
}

.feature-text {
  padding: 15px;
}

.feature-img-wrap {
  position: relative;
}

.feature-img-wrap::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: var(--bgImg);
  background-size: cover;
  opacity: 0.4;
  border-radius: 5px;
  background-position: center;
}

.feature-img {
  height: 100%;
}

.feature-title {
  padding: 10px 0;
  font-weight: bold;
  color: #b83c00;
  font-size: 30px;
}

.feature-descp {
  font-size: 23px;
}

.icons {
  font-size: 35px;
  vertical-align: top;
}

@media (max-width: 600px) {
  .feature-title {
    font-size: 25px;
    text-align: center;
  }
  .feature-descp {
    font-size: 21px;
    text-align: center;
  }
  .feature-img {
    height: 200px;
  }
  .features-items div:nth-child(even) {
    .feature {
      flex-direction: column-reverse;
    }
  }
}
</style>